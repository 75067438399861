import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout imageVariation="5">
    <SEO title="Contact" />
    <div class="mb-8">
      <h1 className="uppercase font-bold text-4xl mb-4"> Contact</h1>
      <p>All management & booking enquries:</p>
      <p>
        <a href="mailto:matt@neptunemgmt.co.uk">
          Matt Bennett - Matt@neptunemgmt.co.uk
        </a>
      </p>
    </div>
  </Layout>
)

export default SecondPage
